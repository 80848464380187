import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/design/design/node_modules/@primer/gatsby-theme-doctocat/src/components/layout.js";
import ComponentLayout from '~/src/layouts/component-layout';
import { Link, Text } from '@primer/react';
import { Link as GatsbyLink } from 'gatsby';
import { AccessibilityLink } from '~/src/components/accessibility-link';
export const _frontmatter = {};
const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};
const Note = makeShortcode("Note");
const layoutProps = {
  _frontmatter
};
const MDXLayout = ComponentLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <Note variant="warning" mdxType="Note">
  <Text sx={{
        display: 'block',
        fontWeight: 'bold',
        mb: 2
      }} mdxType="Text">Guidelines for this component are in progress</Text>
  <Text mdxType="Text">Interested in contributing? Review the guidelines for <Link as={GatsbyLink} to="/guides/contribute/documentation#documenting-components" mdxType="Link">writing component documentation</Link> and open a pull request in our <Link as={GatsbyLink} to="https://github.com/primer/design" mdxType="Link">documentation repository.</Link></Text>
    </Note>
    <Note variant="info" mdxType="Note">
  If you need a more flexible layout component, consider using <Link as={GatsbyLink} to="/components/page-layout" mdxType="Link">PageLayout</Link> instead.
    </Note>
    <h2>{`Usage`}</h2>
    <p>{`This component provides structure for a split layout, including independent scrolling for the pane and content regions. This is useful for responsive list and detail patterns when an item in the pane updates the page content on selection.`}</p>
    <h2>{`Accessibility`}</h2>
    <h3>{`Known accessibility issues (GitHub staff only)`}</h3>
 <AccessibilityLink label="SplitPageLayout" mdxType="AccessibilityLink" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      